import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  MenuFilesDisplay,
  ImageCollection,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class CateringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <HeroElement
              header={"Catering"}
              tagline={""}
              images={getPageHeroImage({
                options: fishermanBusinessWebsitePage.components,
              })}
              ctas={[
                fishermanBusiness.ctas.phone,
                { text: "Contact Us", link: "/contact/", internal: true },
              ]}
              ctaInverted={true}
              ctaColor={"white"}
              containerColor={"primary"}
              textColor={"white"}
              showMultiple={true}
              overlay={true}
              fullWidth={true}
              parallax={false}
              containerAs={"none"}
              height={500}
              gutter={false}
            />
          }
        >
          <Grid
            stackable={true}
            className={
              "component-section-container catering-page content-container"
            }
            centered={true}
          >
            <Grid.Column width={10} textAlign={"center"}>
              <div className={"italic"}>
                We offer catering, pick up, drop off services. For standard
                catering orders, we can prepare within 48 hours. For last minute
                orders, please call the business and we will do our best to
                accommodate.
              </div>
              <MenuFilesDisplay
                files={[
                  {
                    url: "https://fisherman.gumlet.io/public/koto/White_Photocentric_Food_Lunch_Menu.png.PNG",
                    filename: "Menu",
                  },
                ]}
              />
            </Grid.Column>
          </Grid>
          <Grid
            stackable={true}
            className={
              "component-section-container catering-page gallery-container"
            }
            verticalAlign={"middle"}
            textAlign={"center"}
            centered={true}
          >
            <Grid.Column width={12}>
              <ImageCollection
                images={[
                  "https://fisherman.gumlet.io/public/koto/SalmonCateringOrder_KotoHibachi_KMendiola-1.jpg",
                  "https://fisherman.gumlet.io/public/koto/NoodlesCateringOrder_KotoHibachi_KMendiola-1.jpg",
                  "https://fisherman.gumlet.io/public/koto/ShrimpCateringOrder_KotoHibachi_KMendiola-1.jpg",
                  "https://fisherman.gumlet.io/public/koto/ChickenCateringOrder_KotoHibachi_KMendiola-2.jpg",
                  "https://fisherman.gumlet.io/public/koto/FriedRiceCateringOrder_KotoHibachi_KMendiola-1.jpg",
                  "https://fisherman.gumlet.io/public/koto/GroupCateringOrder_KotoHibachi_KMendiola-1.jpg",
                  "https://fisherman.gumlet.io/public/koto/GroupCateringOrder_KotoHibachi_KMendiola-2.jpg",
                ]}
                as={"gallery"}
                centered={true}
                cutUnevenRows={false}
                disable={"none"}
                header={"Photos"}
                numberOfRowDisplayItems={3}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      ctas {
        phone {
          text
          link
          internal
        }
      }
    }
    fishermanBusinessWebsitePage(title: { eq: "Catering" }) {
      title
      description
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
